import { InputResponse, OutputResponse } from "@src/resync/pillar/types";

export const checkSecureInput = async (input: string, metadata?: object) => {
  try {
    const response = await fetch(
      "https://retune.pillarseclabs.com/api/v1/scan/prompt",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_PILLAR_API_KEY}`,
        },
        body: JSON.stringify({
          message: input,
          metadata: metadata ? metadata : {},
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: InputResponse = await response.json();

    if (data.findings.length > 0) {
      throw new Error(`Thread Detected: ${data.findings}`);
    }

    return input;
  } catch (error) {
    throw error;
  }
};

export const checkSecureOutput = async (output: string, metadata?: object) => {
  try {
    const response = await fetch(
      "https://retune.pillarseclabs.com/api/v1/scan/response",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_PILLAR_API_KEY}`,
        },
        body: JSON.stringify({
          message: output,
          metadata: metadata ? metadata : {},
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: OutputResponse = await response.json();

    if (data.findings.length > 0) {
      throw new Error(`Thread Detected: ${data.findings}`);
    }

    return output;
  } catch (error) {
    throw error;
  }
};
